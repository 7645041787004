import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import React from 'react'
import InvestorBanner from '../components/koble/InvestorBanner'

export const InvestorPageTemplate = ({ investorBanner }) => {
  return (
    <div>
      <InvestorBanner {...investorBanner} />
    </div>
  )
}

InvestorPageTemplate.propTypes = {
  investorBanner: PropTypes.object
}

const InvestorPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <InvestorPageTemplate investorBanner={post.frontmatter.investorBanner} />
    </Layout>
  )
}

InvestorPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default InvestorPage

export const investorPageQuery = graphql`
  query InvestorPage {
    markdownRemark(frontmatter: { templateKey: { eq: "investors-page" } }) {
      html
      frontmatter {
        investorBanner {
          title {
            cssClass
            text
          }
          subtitle
          reasons {
            reason
          }
          prompt
          thanks
          error
          video {
            extension
            publicURL

            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
    }
  }
`
