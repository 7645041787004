import React from 'react'
import PropTypes from 'prop-types'
import '../scss/investor-banner.scss'
import Word from './Word'
import CTAButton from '../common/CTAButton'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default function InvestorBanner(props) {
  const {
    title,
    subtitle,
    reasons,
    video,
    prompt,
    thanks,
    error: errorMessage
  } = props

  const [submitted, setSubmitted] = React.useState(false)
  const [error, setError] = React.useState(false)

  const emailRef = React.useRef()

  const submitHandler = (event) => {
    event.preventDefault()
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': event.target.getAttribute('name'),
        email: emailRef.current.value
      })
    })
      .then(() => setSubmitted(true))
      .catch(() => setError(true))
  }

  return (
    <React.Fragment>
      <div className="position-relative video-container">
        <div className="w-50 w-m-100 text-banner">
          <div className="text-container">
            <div className="styled-text d-flex">
              {title.map((item) => (
                <Word key={item.text} {...item} />
              ))}
            </div>
            <div className="subtitle">{subtitle}</div>
            <ul className="reasons-container">
              {reasons.map(({ reason }, index) => (
                <li className="reason" key={index}>
                  {reason}
                </li>
              ))}
            </ul>
            <div className="prompt">{prompt}</div>
            {submitted && <div>{thanks}</div>}
            {!submitted && (
              <form
                className="email-form"
                name="investor-contact"
                method="POST"
                data-netlify="true"
                netlify-honeypot="bot-field"
                onSubmit={submitHandler}
              >
                <input
                  type="hidden"
                  name="form-name"
                  value="investor-contact"
                />
                <p className="hidden">
                  <label>
                    Don’t fill this out if you’re human:{' '}
                    <input name="bot-field" />
                  </label>
                </p>
                <input
                  className="email-input"
                  type="email"
                  name="email"
                  placeholder="Your email address"
                  required
                  ref={emailRef}
                ></input>
                <CTAButton
                  text="Submit"
                  type="submit"
                  rightCircle={true}
                  rightIcon={false}
                />
              </form>
            )}
            {error && <div>{errorMessage}</div>}
          </div>
        </div>
        <div
          className="margin-top-0 w-50 w-m-100 video-parent"
          style={{
            display: 'grid',
            alignItems: 'center'
          }}
        >
          <video
            src={video.publicURL || video.url}
            className="video-player"
            loop={true}
            autoPlay="autoPlay"
            id="vid"
            muted
          ></video>
        </div>
      </div>
    </React.Fragment>
  )
}

InvestorBanner.propTypes = PropTypes.object
